export const DEFAULT_DISTRIBUTION_FACTOR = 0.03
export const DEFAULT_ONBOARDING_FEE = 0.06
export const DEFAULT_CLOSING_AND_LEGAL_FEE = 0.01
export const DEFAULT_PRICE_PER_SHARE = 111.94
export const CURRENT_LOAN_TO_VALUE_PERCENT = 0.306
export const TOTAL_CASH_FLOW_PAID_OUT = 730000
export const SHARE_PRICE_LAST_UPDATED = 'June 30, 2024'
export const FLOCK_MANAGEMENT_FEE = 0.01
export const DEFAULT_FUND_EQUITY_PERCENT_TARGET = 0.65
export const FLOCK_FUND_STARTING_YEAR = 2021
export const FLOCK_FUND_STARTING_QUARTER = 2
export const VALID_STATES = [
  'AL',
  'AR',
  'AZ',
  'CO',
  'FL',
  'GA',
  'IA',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MI',
  'MO',
  'MS',
  'NC',
  'NM',
  'NV',
  'OH',
  'OK',
  'PA',
  'SC',
  'TN',
  'TX',
  'WA',
]

export const VALID_STATES_OVERMOON = ['FL', 'TN']
