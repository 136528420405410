import {
  Box,
  IconButton,
  styled,
  Typography,
  CircularProgress,
  Drawer,
  Grid,
  Slider,
  SliderProps,
  TextField,
} from '@mui/material'
import {
  TrackedButton,
  CloseMobileIcon,
  TrackedLink,
  Checkbox,
} from '@flock/shared-ui'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { PreferencesModalProps } from './PreferencesModal'

const SettingsGroupHeaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

interface StyledSliderProps extends SliderProps {
  topup?: boolean
}

const StyledSlider = styled(Slider, {
  shouldForwardProp: (prop) => prop !== 'topup',
})<StyledSliderProps>(({ topup }) => ({
  color: topup ? '#891A1A' : 'currentColor',
  '& .MuiSlider-track': {
    height: '2px',
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: 'currentColor',
    border: '3px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: topup ? '#F6EDED' : '#E1EEEF',
    color: topup ? '#891A1A' : '#03341D',
    borderRadius: 4,
    position: 'absolute',
    top: '70px',
    width: '72px',
    padding: '4px',
    '&::before': {
      bottom: '96%',
    },
  },
}))

const CancelModal = (props: any) => {
  const { open, onClose, onCloseBoth } = props

  const trackingName = 'cancel-modal'

  return (
    <Drawer open={open} PaperProps={{ sx: { width: '100%' } }}>
      <Box position="fixed" top="8px" right="8px">
        <IconButton onClick={onClose}>
          <CloseMobileIcon />
        </IconButton>
      </Box>
      <Grid columns={4} spacing={2} pl="32px" pr="32px">
        <Box width="100%" display="flex" flexDirection="column" gap="24px">
          <SettingsGroupHeaderWrapper
            sx={{ width: '100%', marginBottom: '1rem', pt: '64px' }}
          >
            <Typography variant="h3">
              Did you mean to exit Quarterly Cash Flow?
            </Typography>
          </SettingsGroupHeaderWrapper>
          <Typography variant="p2">
            Your changes will be lost if you don&apos;t confirm the allotment.
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center"
          >
            <TrackedButton
              trackingConfig={{ name: `${trackingName}-exit` }}
              variant="secondary"
              size="smallForm"
              onClick={onCloseBoth}
            >
              Exit
            </TrackedButton>
            <TrackedButton
              variant="primary"
              size="smallForm"
              onClick={onClose}
              trackingConfig={{ name: `${trackingName}-continue` }}
            >
              Continue editing
            </TrackedButton>
          </Box>
        </Box>
      </Grid>
    </Drawer>
  )
}

const PreferencesMobileDrawer = (props: PreferencesModalProps) => {
  const {
    open,
    cashFlowAllotment,
    cashFlowSelection,
    totalEquity,
    onSubmit,
    onClose,
  } = props
  const totalEquityQuarter = totalEquity / 4
  const [loading, setLoading] = useState(false)
  const [timeHorizonSlider, setTimeHorizonSlider] = useState(
    cashFlowSelection as number
  )
  const [nextPage, setNextPage] = useState(false)
  const [acknowledged, setAcknowledged] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  const closeBothModals = () => {
    setTimeHorizonSlider(cashFlowSelection as number)
    setNextPage(false)
    setAcknowledged(false)
    setCancelModalOpen(false)
    onClose()
  }

  useEffect(() => {
    setTimeHorizonSlider(cashFlowSelection as number)
  }, [cashFlowSelection])

  const trackingName = 'preferences-modal'

  const submitAndClose = async () => {
    setLoading(true)
    await onSubmit(parseFloat(timeHorizonSlider.toFixed(2)))
    setLoading(false)
    onClose()
  }

  return (
    <Drawer open={open} PaperProps={{ sx: { width: '100%' } }}>
      <Box position="fixed" top="8px" right="8px">
        <IconButton
          onClick={
            timeHorizonSlider !== cashFlowSelection
              ? () => setCancelModalOpen(true)
              : onClose
          }
        >
          <CloseMobileIcon />
        </IconButton>
      </Box>
      <Grid columns={4} spacing={2} pl="32px" pr="32px">
        <Box width="100%" display="flex" flexDirection="column" gap="24px">
          <SettingsGroupHeaderWrapper
            sx={{ width: '100%', marginBottom: '1rem', pt: '64px' }}
          >
            <Typography variant="h4">Quarterly Cash Flow</Typography>
          </SettingsGroupHeaderWrapper>
          <Box>
            {nextPage ? (
              <Box display="flex" flexDirection="column" gap="24px">
                <Box display="flex" flexDirection="row">
                  <Typography variant="p2">
                    We&apos;ll deposit{' '}
                    <span
                      style={{
                        color:
                          timeHorizonSlider > cashFlowAllotment
                            ? '#891A1A'
                            : '#136D42',
                      }}
                    >
                      $
                      {Math.round(
                        timeHorizonSlider * (totalEquityQuarter * 1.1) * 0.01
                      ).toLocaleString()}
                    </span>{' '}
                    into your account each quarter.
                  </Typography>
                </Box>
                <hr style={{ width: '100%', color: 'gray2.main' }} />
                <Typography variant="p2" color="gray6.main">
                  {`Exact dollar amounts are subject to change as quarterly financials are finalized. `}
                </Typography>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                padding="0px 16px"
                gap="64px"
              >
                <StyledSlider
                  min={0}
                  value={timeHorizonSlider}
                  defaultValue={cashFlowSelection}
                  onChange={(_, value) =>
                    setTimeHorizonSlider(
                      Array.isArray(value) ? value[0] : value
                    )
                  }
                  max={cashFlowAllotment + 2}
                  step={0.01}
                  topup={timeHorizonSlider > cashFlowAllotment}
                  valueLabelDisplay="on"
                  valueLabelFormat={(value) => (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="p2" fontWeight={500}>{`$${Math.round(
                        value * (totalEquityQuarter * 1.1) * 0.01
                      ).toLocaleString()}`}</Typography>
                      <Typography variant="p3" fontWeight={500}>
                        per qtr
                      </Typography>
                    </Box>
                  )}
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  gap="8px"
                >
                  <Box
                    width="88px"
                    height="40px!important"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap="2px"
                  >
                    <TextField
                      name="flow"
                      type="number"
                      value={timeHorizonSlider.toFixed(2)}
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{
                        min: 0,
                        step: 0.01,
                        style: {
                          textAlign: 'center',
                          padding: '0px!important',
                        },
                      }}
                      sx={{
                        '& > input': {
                          padding: 0,
                        },
                        '& > div > input': {
                          fontSize: '1rem!important',
                          padding: '0!important',
                          height: '40px!important',
                          color:
                            timeHorizonSlider > cashFlowAllotment
                              ? '#891A1A'
                              : '#03341D',
                        },
                      }}
                      InputLabelProps={{
                        style: { padding: '0px!important' },
                      }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        // remove non-numeric characters and trim leading zeros
                        let inputValue = e.target.value.replace(/[^\d]/g, '')
                        inputValue = inputValue.replace(/^0+(?=\d)/, '')
                        // if the input is longer than 3 characters, start from last character
                        if (inputValue.length > 3) {
                          inputValue = inputValue.slice(3)
                        }
                        if (Number(inputValue) / 100 <= cashFlowAllotment + 2) {
                          setTimeHorizonSlider(Number(inputValue) / 100)
                        } else {
                          setTimeHorizonSlider(cashFlowAllotment + 2)
                        }
                      }}
                    />
                    <Typography variant="p2">%</Typography>
                  </Box>
                  <Typography variant="p2">in annualized cash flow</Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      <Box
        position="fixed"
        justifyContent="space-between"
        alignItems="center"
        bottom="40px"
        left="32px"
        right="32px"
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Box display="flex" flexDirection="column" gap="24px">
            {timeHorizonSlider > cashFlowAllotment && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="row"
                alignItems="center"
                gap="8px"
              >
                <Checkbox
                  checked={acknowledged}
                  onChange={() => {
                    setAcknowledged(!acknowledged)
                  }}
                  label={
                    <Typography variant="p3" color="error.main" fontSize="12px">
                      {`I understand taking cash beyond 3% annually may have negative tax impacts if within my first 3 years at Flock. Additionally, a 10bps fee will be charged for every 1% in cash flow above my ${cashFlowAllotment}% allotment. This fee compensates other Flock clients.`}
                    </Typography>
                  }
                  color="error"
                  sx={{
                    color: '#891A1A',
                    '&.Mui-checked': {
                      color: '#891A1A',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#891A1A',
                    },
                  }}
                />
              </Box>
            )}
            {nextPage ? (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <TrackedButton
                  trackingConfig={{ name: `${trackingName}-previous` }}
                  variant="secondary"
                  size="smallForm"
                  onClick={() => setNextPage(false)}
                >
                  Previous
                </TrackedButton>
                <TrackedButton
                  variant="primary"
                  size="smallForm"
                  disabled={
                    timeHorizonSlider > cashFlowAllotment &&
                    acknowledged === false
                  }
                  onClick={submitAndClose}
                  sx={{
                    marginLeft: '1rem',
                  }}
                  trackingConfig={{ name: `${trackingName}-confirmed` }}
                >
                  Confirm
                </TrackedButton>
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent="flex-end"
                flexDirection="row"
                alignItems="center"
                gap="16px"
              >
                <TrackedLink
                  trackingConfig={{ name: `${trackingName}-cancelled` }}
                  variant="p1"
                  onClick={
                    timeHorizonSlider !== cashFlowSelection
                      ? () => setCancelModalOpen(true)
                      : onClose
                  }
                >
                  Cancel
                </TrackedLink>
                <TrackedButton
                  variant="primary"
                  size="smallForm"
                  disabled={
                    timeHorizonSlider > cashFlowAllotment &&
                    acknowledged === false
                  }
                  onClick={() => setNextPage(true)}
                  sx={{
                    marginLeft: '1rem',
                  }}
                  trackingConfig={{ name: `${trackingName}-next` }}
                >
                  Next
                </TrackedButton>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <CancelModal
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        onCloseBoth={closeBothModals}
      />
    </Drawer>
  )
}

export default PreferencesMobileDrawer
