import { TrackedButton } from '@flock/shared-ui'
import { Close } from '@mui/icons-material'
import {
  Box,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
} from '@mui/material'
import React from 'react'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  maxHeight: '75vh',
  overflowY: 'auto',
  padding: '2rem',
})

type ContributionAgreementSignedModalProps = {
  isOpen: boolean
  onClose: () => void
  openPrimaryCADocusignUrl: () => void
}

const PreContributionAgreementSignedModal = (
  props: ContributionAgreementSignedModalProps
) => {
  const { isOpen, onClose, openPrimaryCADocusignUrl } = props

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalWrapper>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
        <Box display="flex" flexDirection="column" gap="16px">
          <Box textAlign="center">
            <Typography variant="h3" alignSelf="center">
              Welcome to Flock&apos;s Owner Portal!
            </Typography>
          </Box>
          <Typography variant="p2" color="moneyGreen.main">
            This portal is your home base for all Flock onboarding tasks.
            You&apos;ll complete the necessary steps and questionnaires within
            the portal, starting with signing your Contribution Agreement. After
            onboarding is complete, the portal will track your Flock investment
            so you can stay updated on your account performance and all relevant
            Fund updates.
          </Typography>

          <Typography variant="p2" color="moneyGreen.main">
            Please save this website in your bookmarks so you can easily access
            it anytime! You can always reach out to Danielle,
            danielle@flockhomes.com, if you need any help or have any questions.
          </Typography>
          <Box
            width="100%"
            justifyContent="center"
            display="flex"
            flexDirection="row"
          >
            <Box width="287px" pt="16px">
              <TrackedButton
                variant="primary"
                size="mini"
                onClick={() => {
                  openPrimaryCADocusignUrl()
                }}
              >
                Sign Contribution Agreement
              </TrackedButton>
            </Box>
          </Box>
        </Box>
      </ModalWrapper>
    </Modal>
  )
}

export default PreContributionAgreementSignedModal
